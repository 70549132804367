//all imports
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Navbar } from "react-bootstrap";
import { navigate } from "@reach/router";

import logo from "./assets/images/mainlogo.png";
import closeIcon from "./assets/images/close.svg";

//main function
export default function authHeader() {
  //jsx
  return (
    <div className="mainheader check">
      <Navbar bg="" expand="lg">
        <Navbar.Brand>
          <Link to="/">
            {" "}
            <img src={logo} className="img-responsive" />
          </Link>
        </Navbar.Brand>

        <Link to="/" className="back-link">
          <img src={closeIcon} className="img-fluid" />
        </Link>
      </Navbar>
    </div>
  );
}
