import React from "react";
import { Link } from "gatsby";
import { navigateTo } from "gatsby-link";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./assets/css/styles.scss";
import Checkbox from "@material-ui/core/Checkbox";
import Authheader from "../layout/header/autheader";
import { signupPatient } from "../../api/actions/signup";
export default function Signup() {
  const [checked, setChecked] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "First Name must be 3 characters at minimum")
      .required("First Name is required"),
    last_name: Yup.string()
      .min(3, "Last Name must be 3 characters at minimum")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters at minimum")
      .required("Password is required"),
    password2: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmitForm = async (formData) => {
    setError("");
    setIsSubmitting(true);
    if (!checked) {
      setError("Please agree to Terms and Policy");
      setIsSubmitting(false);
      return;
    }
    // delete formData["password2"];
    const result = await signupPatient({
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password: formData.password,
    });

    console.log(result);
    if (result && result.success) {
      navigateTo("signup/confirmation?patient");
    } else if (
      result.data &&
      result.data.data &&
      result.data.data.errors &&
      result.data.data.errors.email
    ) {
      setError(result.data.data.errors.email);
    } else if (result.data && result.data.errors && result.data.errors.email) {
      setError(result.data.errors.email);
    } else {
      setError("Something went wrong, Kindly try again.");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Authheader />
      <div className="signup form">
        <h1>Patient Sign Up</h1>
        <p>Feel confident making some of life’s most important decisions</p>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            password2: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(data) => {
            onSubmitForm(data);
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <div className="formgroup form-group">
                <label htmlFor="first_name">FIRST NAME</label>
                <Field
                  type="text"
                  name="first_name"
                  placeholder="Enter First Name"
                  className={`form-control ${
                    touched.first_name && errors.first_name ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="first_name"
                  className="invalid-feedback"
                />
                <label htmlFor="last_name">LAST NAME</label>
                <Field
                  type="text"
                  name="last_name"
                  placeholder="Enter Last Name"
                  className={`form-control ${
                    touched.last_name && errors.last_name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="last_name"
                  className="invalid-feedback"
                />
                <label htmlFor="email">EMAIL</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`form-control ${
                    touched.email && errors.email ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
                <label htmlFor="password">PASSWORD</label>
                <Field
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  className={`form-control ${
                    touched.password && errors.password ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
                <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
                <Field
                  type="password"
                  name="password2"
                  placeholder="Confirm Password"
                  className={`form-control ${
                    touched.password2 && errors.password2 ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="password2"
                  className="invalid-feedback"
                />

                {/*<div className="agreeterms">
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="agree"
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                   <span>
                    I have read and agree to MedAnswers'
                    <Link to="terms-of-service/patients">
                      {" "}
                      Terms of Service
                    </Link>{" "}
                    and
                    <Link to="privacy"> Privacy Policy.</Link>
                  </span> 
                </div>*/}
                <br />
                {error && <p style={{ color: "red" }}>{error}</p>}

                <button type="submit" className="next" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting...  " : "Next"}
                </button>

                <h3>
                  Already have an Account?
                  <Link to="/login"> Sign in</Link>
                </h3>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
