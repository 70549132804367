import API from "../utils/axios";

export const signupPatient = async (data) => {
  try {
    const response = await API.post("/users", data, {
      "Content-Type": "application/json",
    });
    return { success: 1 };
  } catch (err) {
    return { success: 0, data: err.response };
  }
};

export const signupExpert = async (data) => {
  try {
    const response = await API.post("/experts", data, {
      "Content-Type": "application/json",
    });
    return { success: 1 };
  } catch (err) {
    return { success: 0, data: err };
  }
};
